<template>
  <div class="relative">
    <div
      v-if="!isClientLoaded || !isEditorLoaded"
      class="w-full bg-black bg-opacity-5 rounded-md loading"
      style="height: 300px"
    />
    <ClientOnly>
      <div v-show="isClientLoaded && isEditorLoaded">
        <textarea
          ref="textareaRef"
          style="height: 300px; border: 2px solid #eee"
          class="w-full rounded-md m-0 p-0"
          v-model="localContent"
        />
        <Button
          variant="default"
          @click="handleGetContent()"
          class="mt-4 absolute right-4 bottom-10 bg-primary-violet px-8"
        >
          {{ buttonLabel }}
        </Button>
      </div>
    </ClientOnly>
  </div>
</template>

<script lang="ts" setup>
import { compressImageByRatio } from "../lib/image";

const props = withDefaults(
  defineProps<{
    buttonLabel?: string;
    content?: string;
  }>(),
  {
    buttonLabel: "Enregistrer",
    content: "",
  }
);
const localContent = ref(props.content); // Bind the prop to a local state

const emit = defineEmits<{
  getContent: [value: string]; // Define the event type
  loaded: [editor: any];
}>();
const editorRef = ref<any>(null);
const { apiCaller } = useApiCaller();
const handleGetContent = () => {
  const content = window.tinyMCE.activeEditor.getContent();
  emit("getContent", content);
};

const textareaRef = ref<HTMLTextAreaElement | null>(null);
const isClientLoaded = ref(false);
const isEditorLoaded = ref(false);

const initEditor = () => {
  window.tinyMCE?.init?.({
    target: textareaRef.value,
    license_key: "gpl",
    // language: "fr_FR",
    base_url: "https://cdnjs.cloudflare.com/ajax/libs/tinymce/7.3.0",
    suffix: ".min", // Suffix to use when loading resources
    height: 300,
    plugins: "lists table directionality image", // Included 'table' and 'quickbars' for additional functionality
    toolbar:
      "undo redo | fontsizeinput | forecolor backcolor | bold italic underline strikethrough lineheight formatpainter removeformat | alignleft aligncenter alignright alignjustify | numlist bullist | outdent indent | ltr rtl | table image",
    menubar: false, // Hides the menubar  image_advtab: true,  // Enable advanced tab if needed
    file_picker_types: "image",
    content_style: "body { line-height: 1.8rem; }",
    file_picker_callback: function (callback: any, _value: any, _meta: any) {
      var input = document.createElement("input");
      input.setAttribute("type", "file");
      input.setAttribute("accept", "image/*");
      input.onchange = function () {
        const file = input.files![0];
        var reader = new FileReader();
        reader.onload = async function () {
          var id = "blobid" + new Date().getTime();
          // const imgPath = `email-template/images/${Date.now().toString(16)}.${file.name.split(".").pop()}`;
          // const signedUrl = await apiCaller.uploads.signedUploadUrl.mutate({
          //   path: imgPath,
          // });
          // await uploadFile(file, signedUrl);
          var blobCache = window.tinyMCE.activeEditor.editorUpload.blobCache;
          var blobInfo = blobCache.create(id, file, reader.result);
          blobCache.add(blobInfo);

          // callback(
          //   `https://s3.eu-west-3.amazonaws.com/aws-fr-dev-prospere/${imgPath}`,
          //   {
          //     title: file.name,
          //   }
          // );
          callback(blobInfo.blobUri(), { title: file.name });
        };
        reader.readAsDataURL(file);
      };

      input.click();
    },
    setup(editor: any) {
      editorRef.value = editor;
      editor.on("init", () => {
        isEditorLoaded.value = true;
        emit("loaded", editor);
      });
      editor.on("NodeChange", async function (e: any) {
        if (e.element.tagName === "IMG") {
          const img = e.element;
          // if the image is a blob, upload it to S3
          if (img.src.startsWith("blob:")) {
            let file = await fetch(img.src).then((res) => res.blob());
            file = await compressImageByRatio(file, 512 * 1024);
            const imgPath = `email-template/images/${Date.now().toString(16)}.${file.type.split("/").pop()}`;
            const signedUrl = await apiCaller.uploads.signedUploadUrl.mutate({
              path: imgPath,
            });
            await uploadFile(file, signedUrl);
            img.src = `https://s3.eu-west-3.amazonaws.com/aws-fr-dev-prospere/${imgPath}`;
          }
        }
        return e;
      });
      editor.on("BeforeSetContent", function (e: any) {
        e.content = e.content.replace(
          /\[([^\]]+)\]/g,
          function (_match: any, p1: any) {
            return `<span class="email-template-var-text" style="border-radius: 8px;border: 1px solid #E6E7E8;padding: 3px 10px;color: #4D80C9 !important;font-size: 14px;font-style: normal;font-weight: 400">${p1}</span> `;
          }
        );
      });

      editor.on("PostProcess", function (e: any) {
        if (e.get) {
          e.content = e.content.replace(
            /<span class="email-template-var-text" style="border-radius: 8px;border: 1px solid #E6E7E8;padding: 3px 10px;color: #4D80C9 !important;font-size: 14px;font-style: normal;font-weight: 400">([^<]+)<\/span>/g,
            "[$1]"
          );
        }
      });

      editor.on("keyup", function (_e: any) {
        const content = editor.getContent();
        const newContent = content.replace(
          /<\/span>(\s+)<span/g,
          "$1</span><span"
        );
        if (content !== newContent) {
          editor.setContent(newContent);
        }
      });
    },
  });
};
onMounted(() => {
  isClientLoaded.value = true;
  loadTinyMce().then(() => {
    initEditor();
  });
});

defineExpose({
  getContent: handleGetContent,
  clearContent: () => {
    editorRef.value.setContent("");
  },
  setContent: (content: string) => {
    editorRef.value.setContent(content);
  },
  reload() {
    nextTick(() => {
      initEditor();
    });
  },
  destroy() {
    editorRef.value.remove?.();
  },
});
</script>
<style lang="css">
body#tinymce p {
  line-height: 1.8rem;
}
</style>
