/**
 * Compress an image by a specified scale ratio.
 *
 * @param {File | Blob} imageFile - The original image file to compress.
 * @param {number} maxSize - The maximum size of the output image in bytes.
 * @returns {Promise<Blob>} A promise that resolves with the compressed image as a Blob.
 * @throws {Error} If the compression fails or the input is invalid.
 */
export async function compressImageByRatio(
  imageFile: File | Blob,
  maxSize: number = 1024 * 1024
): Promise<Blob> {
  if (!(imageFile instanceof File) && !(imageFile instanceof Blob)) {
    throw new Error("Invalid input: imageFile must be a File or Blob.");
  }

  if (typeof maxSize !== "number" || maxSize <= 0) {
    throw new Error("Invalid input: maxSize must be a positive number.");
  }

  const image = await loadImage(imageFile);

  let scaledWidth = image.width;
  let scaledHeight = image.height;
  let compressedBlob = await compressToSize(image, scaledWidth, scaledHeight);

  // Reduce size iteratively if necessary
  while (compressedBlob.size > maxSize) {
    const scaleRatio = Math.sqrt(maxSize / compressedBlob.size);
    scaledWidth = Math.floor(scaledWidth * scaleRatio);
    scaledHeight = Math.floor(scaledHeight * scaleRatio);

    compressedBlob = await compressToSize(image, scaledWidth, scaledHeight);

    // If the image size cannot be reduced further, break to avoid infinite loop
    if (scaledWidth <= 1 || scaledHeight <= 1) {
      throw new Error("Unable to compress image to the desired size.");
    }
  }

  return compressedBlob;
}

/**
 * Load an image from a File or Blob object.
 *
 * @param {File | Blob} file - The image file or blob to load.
 * @returns {Promise<HTMLImageElement>} A promise that resolves with the loaded image element.
 */
function loadImage(file: File | Blob): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = reject;
      img.src = reader.result as string;
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
}

/**
 * Compress an image to specified dimensions.
 *
 * @param {HTMLImageElement} img - The image element to compress.
 * @param {number} width - The desired width of the compressed image.
 * @param {number} height - The desired height of the compressed image.
 * @returns {Promise<Blob>} A promise that resolves with the compressed image as a Blob.
 */
function compressToSize(
  img: HTMLImageElement,
  width: number,
  height: number
): Promise<Blob> {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    if (!ctx) {
      return reject(new Error("Failed to get canvas 2D context."));
    }

    canvas.width = width;
    canvas.height = height;
    ctx.drawImage(img, 0, 0, width, height);

    canvas.toBlob(
      (blob) => {
        if (blob) {
          resolve(blob);
        } else {
          reject(new Error("Canvas is empty or failed to compress image."));
        }
      },
      "image/jpeg",
      0.9 // Use a high quality for the compressed image
    );
  });
}
